.red-n {
    color: red;
    margin-left: 5px; /* Example margin for spacing */
    font-weight: bold; /* Example for emphasis */
    /* Add more styles as needed */
  }
  .editor-container iframe {
    display: block;
    margin: 8px 0;
  }
  
  .editor-container a {
    display: block;
    margin: 8px 0;
  }